





































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EClientType } from '@WorkPlatform/common/const';
import { pageForward } from '@WorkPlatform/service/flow-center';

@Component({
  name: 'app-page',
  components: {},
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) details!: any;

  @Prop({ default: '1' }) todoType!: string;

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  /**
   * 跳转待办详情
   */
  clickHandler (item: any) {
    pageForward(item, EClientType.MOBILE);
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    let theTime: number = parseInt(value, 10); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    if (theTime > 60) {
      theTime1 = parseInt(String(theTime / 60), 10);
      theTime = parseInt(String(theTime % 60), 10);
      if (theTime1 > 60) {
        theTime2 = parseInt(String(theTime1 / 60), 10);
        theTime1 = parseInt(String(theTime1 % 60), 10);
        if (theTime2 > 24) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / 24), 10);
          theTime2 = parseInt(String(theTime2 % 24), 10);
        }
      }
    }

    let result = '';
    if (theTime > 0 && theTime2 <= 0) {
      result = '' + parseInt(theTime + '', 10) + '秒';
    }
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1 + '', 10) + '分' + result;
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2 + '', 10) + '小时' + result;
    }
    if (theTime3 > 0) {
      result = '' + parseInt(theTime3 + '', 10) + '天' + result;
    }

    return result;
  }

  timeOut (value: string) {
    const oldTime: any = new Date(value);
    const newTime: any = new Date();
    const timeout: any = parseInt((newTime - oldTime) + '', 10);
    return this.formatSeconds(timeout / 1000);
  }
}
