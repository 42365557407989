import { WorkPlatformService } from '@WorkPlatform/api';
import { EClientType, EPageType } from '@WorkPlatform/common/const';
import { IFlowItem } from '@WorkPlatform/types/flow-center';
import Vue from 'vue';
import { service } from '@cloudpivot-hermes/nail-free-login';
import { isFreeLoginPlatform, isPhone, isWeChat } from '@WorkPlatform/common/utils';
import cookie from 'js-cookie';

/**
 * 点击 待办项 进行页面的跳转
 */
export function pageForward (item: IFlowItem, type: EClientType) {
  const pageArr =
    typeof item.pageJson === 'string' ? JSON.parse(item.pageJson) : [];

  const pageInfo: any = pageArr.find((v) => v.pageType === type);
  if (pageInfo && pageInfo.pageSource === EPageType.THIRD) {
    goToThirdPage(item, pageInfo);
  } else {
    goToSysPage(item, pageInfo || {}, type);
  }
}

/**
 * 第三方页面跳转
 */
function goToThirdPage (item, pageInfo) {
  try {
    const s = window.open(
      `${pageInfo.pageCode}?workItemId=${item.id}&instanceId=${item.instanceId}&schemaCode=${item.schemaCode}`,
      '_blank');
    if (!s) {
      // throw new Error('浏览器新开弹窗未开启');
    }
  } catch (e) {
    Vue.prototype.$errorcatcher.showMessagePopup({
      title: '错误提示！',
      message: [e, '!'],
      displayFooter: false,
      correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
    });
  }
}

/**
 * 系统页面跳转
 */
function goToSysPage (item, pageInfo, type) {
  const params: any = {
    appCode: item.appCode,
  };

  WorkPlatformService.getAppUrl(params).then((res: any) => {
    if (res.success) {
      const tenantCode = cookie.get('tenantCode') || sessionStorage.getItem('tenantCode');
      const query: any = {
        pageCode: '',
        workItemId: item.id,
        workflowInstanceId: item.instanceId,
        appVersion: item.appVersion,
        workflowCode: item.workflowCode,
        isFromTask: true, // ui-与流程解耦 新增字段
        modelCode: item.schemaCode,
        schemaCode: item.schemaCode,
        ...pageInfo,
        tenantCode,
        openType: 'window', // 固定值，UI多页运行增加
        pageMode: 'edit', // 固定值，UI多页运行增加
      };
      if (item.appVersion === '0.0.0' && !res.data.includes('isMock')) {
        query.isMock = 1;
      }
      const str: string = Object.entries(query)
        .map((queryArr: any[]) => {
          return queryArr.join('=');
        })
        .join('&');

      let url = '';
      if (res.data.includes('?')) {
        url = `${location.origin}${res.data}&${str}`;
      } else {
        url = `${location.origin}${res.data}?${str}`;
      }

      if (process.env.NODE_ENV !== 'production') {
        url = `http://localhost${res.data.substring(
          res.data.indexOf('/app_launcher/'),
        )}?${str}`;
      }

      try {
        const token = localStorage.getItem('token');
        const jumpTime: number = 100;
        if (isWeChat() && isPhone()) {
          window.history.pushState({}, '');
          window.location.replace(url);
        } else if (isFreeLoginPlatform()) {
          service.openLink(`${url}&token=${token}`);
          if (isPhone()) {
            document.addEventListener('resume', dingBackReload);
          }
        } else {
          setTimeout(() => {
            if (type === EClientType.PC) {
              const winOpen: any = window.open(url, '_blank');
              if (!winOpen) {
                throw new Error('浏览器新开弹窗未开启');
              }
            } else {
              window.history.pushState({}, '');
              window.location.replace(url);
            }
          }, jumpTime);
        }
      } catch (e) {
        Vue.prototype.$errorcatcher.showMessagePopup({
          title: '错误提示！',
          message: [e, '!'],
          displayFooter: false,
          correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
        });
      }
    }
  });
}

function dingBackReload () {
  location.reload();
  document.removeEventListener('resume', dingBackReload);
}

/**
 * 跳转进App
 */
export function appForward (item: any, type: EClientType, indexPageCode:string) {
  sessionStorage.setItem('appEntrance', 'platform');
  const params: any = {
    appCode: item.appCode,
    appVersion: item.version,
  };

  WorkPlatformService.getAppUrl(params).then((res: any) => {
    if (res.success) {
      const homePage: any = item.homePage;
      if (homePage && homePage.groupCode === 'unGrouped') {
        homePage.groupCode = '';
      }
      const tenantCode = sessionStorage.getItem('tenantCode');
      const query: any = {
        groupCode: '',
        moduleCode: '',
        pageGroupCode: '',
        pageCode: indexPageCode || '',
        appVersion: item.version,
        workflowCode: item.workflowCode,
        ...homePage,
        tenantCode,
      };
      const str: string = Object.entries(query)
        .map((queryArr: any[]) => {
          return queryArr.join('=');
        })
        .join('&');

      let url = `${location.origin}${res.data}?${str}`;

      if (process.env.NODE_ENV !== 'production') {
        url = `http://localhost${res.data.substring(
          res.data.indexOf('/app_launcher/'),
        )}?${str}`;
      }

      try {
        const token = localStorage.getItem('token');
        const jumpTime: number = 100;
        if (isWeChat() && isPhone()) {
          location.href = `${url}&token=${token}`;
        } else if (isFreeLoginPlatform()) {
          service.openLink(`${url}&token=${token}`);
        } else {
          setTimeout(() => {
            if (type === EClientType.PC) {
              const s = window.open(url, '_blank');
              if (!s) {
                throw new Error('浏览器新开弹窗未开启');
              }
            } else {
              window.history.pushState({}, '');
              window.location.replace(url);
            }
          }, jumpTime);
        }
      } catch (e) {
        Vue.prototype.$errorcatcher.showMessagePopup({
          title: '错误提示！',
          message: [e, '!'],
          displayFooter: false,
          correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
        });
      }
    }
  });
}
